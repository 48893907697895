<template>
	<div>
		<!-- 个人中心 基本信息卡片 -->
		<a-card
			:bordered="false"
			class="header-solid h-full card-profile-information"
			:bodyStyle="{ paddingTop: 0, paddingBottom: '16px' }"
			:headStyle="{ paddingRight: 0 }">
			<template #title>
				<h6 class="font-semibold m-0">基本信息</h6>
			</template>
			<!-- <a-button type="link" slot="extra" @click="handleForm('mobile')">
				<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path class="fill-muted"
						d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
						fill="#111827" />
					<path class="fill-muted" d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
						fill="#111827" />
				</svg>
			</a-button> -->

			<a-descriptions title="" :column="1">
				<a-descriptions-item label="昵称"
					>{{ userInfo.nickname }} <span style="font-size: 12px; color: #999">（UID：{{ userInfo.user_id }}）</span>
				</a-descriptions-item>
				<a-descriptions-item label="手机号" style="position: relative"
					>{{ mobile ? mobile : "未绑定" }}
					<span
						@click="handleForm('mobile')"
						v-if="mobile == ''"
						style="display: inline-block; position: absolute; top: 105px; right: 20px; color: #00a1d6; cursor: pointer">
						绑定手机</span
					>
					<span
						@click="handleForm('mobile')"
						v-else
						style="display: inline-block; position: absolute; top: 105px; right: 20px; color: #00a1d6; cursor: pointer">
						更改手机</span
					>
				</a-descriptions-item>
				<a-descriptions-item label="登录密码" style="position: relative"
					>*********
					<span
						@click="handleForm('password')"
						style="display: inline-block; position: absolute; top: 145px; right: 20px; color: #00a1d6; cursor: pointer">
						设置密码</span
					>
				</a-descriptions-item>
				<!-- <a-descriptions-item label="创作身份">{{ userInfo.is_vip === 1 ? userInfo.vip_expiretime_text : "普通用户" }}</a-descriptions-item> -->
				<!-- <a-descriptions-item label="分销商">{{ userInfo.reseller ? userInfo.reseller.reseller_json.name : "无" }}</a-descriptions-item> -->

				<a-descriptions-item v-if="!userInfo.idenauthen_status" label="实名认证" style="position: relative">
					未实名
					<div
						@click="handleForm('name')"
						style="
							display: inline-block;
							position: absolute;
							bottom: 72px;
							right: 20px;
							color: #00a1d6;
							cursor: pointer;
						">
						<span v-if="!userInfo.idenauthen_status">实名认证</span>
					</div>
					<!-- <div v-else
						style="display: inline-block; position: absolute; bottom: 72px; right: 20px;color: #00a1d6">
						已实名 </div> -->
				</a-descriptions-item>
				<a-descriptions-item v-else label="实名认证"
					>{{ userInfo.idenauthen_name }}
					<div style="display: inline-block; position: absolute; bottom: 72px; right: 20px; color: #00a1d6">已实名</div>
				</a-descriptions-item>
				<a-descriptions-item label="注册时间">{{ joinTime }}</a-descriptions-item>
			</a-descriptions>
		</a-card>
		<!-- 更改电话弹窗 -->
		<a-modal v-model="visible" :title="title" ok-text="确认" cancel-text="取消" @ok="hideModal">
			<div class="input_box">
				<a-form :form="form" layout="horizontal">
					<div v-if="isType">
						<a-form-item label="手机号">
							<div class="input pl">
								<a-input prefix="+86" placeholder="请输入手机号" v-decorator="rules.mobile" />
							</div>
						</a-form-item>
						<a-form-item v-if="isMoblie" label="新密码">
							<div class="input pl">
								<a-input placeholder="请输入新密码" v-decorator="rules.password">
									<a-icon slot="prefix" type="lock" />
								</a-input>
								<!-- <a-icon slot="prefix" type="user" /> -->
							</div>
						</a-form-item>
						<a-form-item has-feedback label="验证码">
							<a-input placeholder="请输入验证码" v-decorator="rules.code">
								<a-icon slot="prefix" type="safety-certificate" />
								<div class="code" slot="suffix" @click="getMobileCode">{{ code.tips }}</div>
							</a-input>
						</a-form-item>
					</div>
					<div v-else>
						<a-form-item label="真实姓名">
							<a-input placeholder="请输入姓名" v-decorator="rules.name" />
						</a-form-item>
						<a-form-item has-feedback label="身份证号">
							<a-input placeholder="请输入身份证号码" v-decorator="rules.idno"> </a-input>
						</a-form-item>
					</div>
				</a-form>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import { timestampToTime } from "@/utils/tools.js";
	// import { getUrlParams } from "@/utils/tools"
	import { getUrlParams } from "@/utils/tools";
	import { checkTelPhone, checkPassword, checkCard } from "@/utils/verify";
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
	export default {
		data() {
			return {
				joinTime: "",
				title: "", //
				visible: false,
				form: this.$form.createForm(this, {
					// 电话号码
					mobile: "",
					code: "",
					password: "",
					// 实名认证
					name: "",
					idno: "",
				}),
				rules: {
					mobile: ["mobile", { rules: [{ required: true, message: "请输入手机号" }, { validator: checkTelPhone }] }],
					code: [
						"code",
						{
							rules: [
								{ required: true, message: "请输入验证码" },
								{ min: 4, max: 6, message: "请输入4~6位验证码" },
							],
						},
					],
					name: ["name", { rules: [{ required: true, message: "请输入姓名" }] }],
					password: [
						"password",
						{ rules: [{ required: true, message: "请输入新密码" }, { validator: checkPassword }] },
					],
					idno: ["idno", { rules: [{ required: true, message: "请输入身份证号码" }] }],
				},
				code: {
					tips: "获取验证码",
					seconds: 60,
					status: true,
					task: null,
				},
				isType: "",
				isMoblie: "",
				type: "code", // (code / password / wx / register / forgot)
				qrcode: {
					// loading: true, // 二维码加载中
					// invalid: false, // 二维码失效
					// img: "", // 二维码图片
					// scan: false, // 扫码状态
					// task: null, // 定时任务
					bind: false, // 绑定手机号
					auth: "", // 微信授权ID
				},
				mobile: "",
			};
		},
		computed: {
			...mapGetters("user", ["userInfo"]),
		},
		created() {
			this.joinTime = timestampToTime(this.userInfo.createtime);

			// this.userInfo.mobile = ''
			this.getUserInfo();
			if (this.userInfo.mobile != "") {
				this.mobile = this.userInfo.mobile.substr(0, 3) + "******" + this.userInfo.mobile.substr(9);
			} else {
				this.mobile = this.userInfo.mobile;
			}
		},
		methods: {
			...mapActions("user", ["getUserInfo"]),
			// 实名认证
			idenauthen(data) {
				this.$http("user.idenauthen", data).then((res) => {
					if (res.code === 1) {
						this.$message.success("认证成功");
					} else {
						this.$message.error("认证失败，请检查信息是否正确");
					}
				});
			},
			handleForm(type) {
				// console.log("99", type);
				switch (type) {
					case "name":
						this.title = "实名认证";
						break;
					case "mobile":
						this.userInfo.mobile == "" ? (this.title = "绑定手机号") : (this.title = "更改手机号");
						this.isMoblie = false;
						break;
					case "password":
						this.title = "设置密码";
						this.isMoblie = true;
						break;
					default:
						break;
				}
				type == "name" ? (this.isType = false) : (this.isType = true);
				this.visible = true;
			},
			// 确定
			hideModal(e) {
				e.preventDefault();
				this.form.validateFields((err, values) => {
					if (!err) {
						console.log("表单数据", values);
						// 更改密码
						if (values.mobile && values.code && values.password) {
							this.forgotPassword(values);				
						}
						if (values.mobile && values.code && !values.password) {
							// 绑定手机号
							this.bindAuthMobile(values);	
						}
						// 实名认证
						if (values.idno && values.name) {
							this.idenauthen(values);
						}
						this.visible = false;
					}
				});
			},
			// 阿里云实名认证接口
			resetForm() {
				this.$refs.ruleForm.resetFields();
			},
			// 4.找回密码
			forgotPassword(obj) {
				this.$http("login.forgotPassword", obj).then((res) => {
					if (res.code === 1) {
						this.$message.success("密码修改成功");
						// this.type = "password"
					}
				});
			},
			// 5.绑定授权手机号
			bindAuthMobile(bind) {
				let obj = {
					user_oauth_id: this.qrcode.auth,
					spm: getUrlParams().share || "",
				};
				const api = () => {
					this.$http("login.bindMobile", obj).then((res) => {
						if (res.code === 1) {
							this.$message.success("绑定成功");
							// this.loginModal = false
							this.setToken(res.data.token);
							this.getUserInfo(res.data.token);
						}
					});
				};
				if (!bind) return api();
				this.form.validateFields(["mobile", "code"], { force: true }, (errors, values) => {
					if (!errors) {
						obj = { ...obj, mobile: values.mobile, code: values.code };
						api();
					}
				});
			},
			// 获取验证码
			getMobileCode() {
				const { mobile } = this.form.getFieldsValue();
				if (!mobile) {
					this.$message.error("请输入手机号");
					return;
				}
				let sendType = "";
				if (this.title == "更改手机号" || this.title == "绑定手机号") {
					sendType = "changemobile";
				} else if (this.title == "设置密码") {
					sendType = "resetpwd";
				}
				console.log(this.title, "22");
				console.log(this.form, "22");
				this.$http("login.sendCode", { mobile: mobile, event: sendType }).then((res) => {
					if (res.code === 1) {
						this.$message.success("验证码发送成功，请注意查收");
						this.code.task = setInterval(() => {
							setTimeout(() => {
								this.code.seconds--;
								this.code.tips = this.code.seconds + "秒重新获取";
								if (this.code.seconds <= 0) {
									this.code.seconds = 60;
									this.code.tips = "重新获取";
									this.code.status = true;
									clearInterval(this.code.task);
								}
							}, 0);
						}, 1000);
					} else {
						this.code.status = true;
					}
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	::v-deep .ant-modal-content {
		border-radius: 10px;
	}

	.input_box {
		::v-deep .ant-form {
			.ant-form-item {
				margin-bottom: 14px;
			}

			.ant-form-item-with-help {
				margin-bottom: -4px !important;
			}

			.ant-input {
				letter-spacing: 1px;

				&::placeholder {
					font-weight: normal;
				}
			}

			.pl {
				.ant-input {
					// padding-left: 40px;
				}
			}

			.code {
				cursor: pointer;
			}
		}
	}

	.c-pointer {
		cursor: pointer;
	}

	.ant-form-item {
		display: flex !important;
		width: 100%;
	}

	::v-deep .ant-input-affix-wrapper .ant-input-prefix {
		left: 5px !important;
	}

	::v-deep .ant-descriptions .ant-descriptions-item-label {
		font-weight: normal !important;
	}

	::v-deep .ant-descriptions .ant-descriptions-item-content {
		font-weight: normal !important;
	}

	::v-deep .ant-input {
		width: 280px !important;
	}
</style>
